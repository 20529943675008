import React from "react"
import { getCourseNavigation } from "../../../store/courses"
import LayoutCourse from "../../../layouts/course"

import Column from "../../../components/column"
import Columns from "../../../components/columns"
import CourseFooterNext from "../../../components/course-footer-next"
import Heading from "../../../components/heading"
import InfoBox from "../../../components/info-box"
import Seo from "../../../components/seo"
import Stack from "../../../components/stack"
import Text from "../../../components/text"
import TextLink from "../../../components/textlink"

const Page = () => {
  const navigation = getCourseNavigation({ courseId: "jahreszeiten" })

  return (
    <LayoutCourse
      footer={
        <CourseFooterNext
          title="Bist du bereit?"
          label="Los Geht's"
          to="/kurse/jahreszeiten/01-jahreszeitengedicht"
        />
      }
      navigation={navigation}
    >
      <Seo title="Hölderlin und die Jahreszeiten" />
      <Stack>
        <Columns
          alignY="center"
          alignX="space-between"
          collapse={[true, false]}
        >
          <Column>
            <Text sans>Ab 5. und 6. Klasse</Text>
          </Column>
          <Column width="content">
            <TextLink to="/kurse/jahreszeiten/fuer-lehrer">
              Informationen für Lehrer*innen
            </TextLink>
          </Column>
        </Columns>
        <Heading as="h2" level={2}>
          Hölderlin und die Jahreszeiten
        </Heading>
        <InfoBox title="Um was geht es?">
          Friedrich Hölderlin liebte die Jahreszeiten. Er hat zahlreiche
          Gedichte darüber verfasst. Eines davon nehmen wir uns in diesem Kurs
          vor. Wir zerlegen es in seine Bestandteile und schauen Hölderlin beim
          Schreiben über die Schulter. Anschließend machst du dich daran, ein
          eigenes Gedicht zu verfassen.
        </InfoBox>
        <InfoBox title="Was ist dein Ziel?">
          Hölderlin war ein echter Profi im Gedichte-Schreiben. Von ihm kann man
          sich eine Menge abschauen. Indem du sein Gedicht ›Der Frühling‹ bis
          ins kleinste Detail erforschst, sammelst du Ideen, Tricks und Kniffe,
          die du am Ende für ein eigenes Gedicht verwenden kannst.
        </InfoBox>
        <InfoBox title="Was lernst du in diesem Kurs?">
          Am Beispiel von Hölderlins Gedicht ›Der Frühling‹ erfährst du etwas
          darüber, wie Gedichte gemacht sind. Du untersuchst die Wörter und
          Silben, aus denen sie bestehen. Du machst dich mit ihrer Form und
          Melodie vertraut und überträgst das Gelernte auf deinen eigenen
          Gedichtvortrag und dein selbstverfasstes Gedicht. Außerdem entzifferst
          du Hölderlins Handschrift und ermittelst, was es mit dem merkwürdigen
          Namen »Scardanelli« auf sich hat.
        </InfoBox>
      </Stack>
    </LayoutCourse>
  )
}

export default Page
